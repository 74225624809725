import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const CustomTagAutocomplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: 24,
  "& .MuiOutlinedInput-root": {
    padding: "11px 12px",
    backgroundColor: "#FFF",
    "&.Mui-focused": {
      boxShadow: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E8E8E8 !important",
      },
    },
    "&:hover": {
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E8E8E8 !important",
      },
    },
    "& .MuiAutocomplete-input": {
      position: "relative",
      backgroundColor: "#FFF",
      fontSize: 14,
      fontFamily: "Poppins-Regular",
      padding: 0,
    },
  },
  "& .Mui-focused": {
    outline: "unset",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E8E8E8 !important",
  },
}));

const CTagAutocomplete = ({
  options,
  onChange,
  value = [],
  label,
  placeholder,
  onAddTag,
  required,
  onInputChange,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      const newTagName = inputValue.trim();
      onAddTag(newTagName); // Trigger the add tag function
      setInputValue(""); // Reset the input
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    // Call the onInputChange prop to handle API calls based on input length
    if (newInputValue.length >= 3) {
      console.log("enter", newInputValue)
      onInputChange(newInputValue); // Trigger the API call in the parent component
    }
  };

  return (
    <FormControl variant="standard" fullWidth required={required}>
      <InputLabel shrink htmlFor="tag-input">
        {label}
      </InputLabel>
      <CustomTagAutocomplete
        multiple
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => option.name}
        freeSolo
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
      />
    </FormControl>
  );
};

export default CTagAutocomplete;
