import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Images from "../../config/images";
import useStyles, { Status } from "./styles";

import { formattedAmount } from "../../utils/CommonFunction";
import moment from "moment";

const PackageCard = (props) => {
  const { villa = {}, type = "", onClick = () => {} } = props;

  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const transformedStatus =
    villa?.status === "open-for-modification"
      ? "Open For Modification"
      : villa?.status === "CREATED" ? 'Draft'
      : villa?.status === "SUBMITTED" ? 'Awaiting Approval'
      : villa?.status
          .split("-")
          .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
          .join(" ");

  const parsedImage =
    villa?.primaryImage && villa?.primaryImage !== ""
      ? JSON.parse(villa?.primaryImage)
      : "";

  return (
    <Card key={villa?.id} className={classes.card} onClick={onClick}>
      <CardMedia
        component="img"
        height="150"
        image={
          parsedImage !== ""
            ? parsedImage?.image
            : "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
        }
        style={{ objectFit: "cover" }}
        alt={"project_image"}
      />
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap="16px"
        >
          <Typography className={classes.name}>
            {villa?.name || "NA"}
          </Typography>
          {villa?.status && (
            <Status
              status={
                villa?.status === "open-for-modification"
                  ? villa?.status
                  : villa?.status
              }
            >
              {transformedStatus}
            </Status>
          )}
        </Stack>
        <div className={classes.rowJustified}>
          <Typography className={classes.code1}>
            <Typography className={classes.code1} color="#8C92A4">
              Created-at:&nbsp;
            </Typography>
            {villa?.createdAt
              ? moment(villa?.createdAt).format("MMM DD, YYYY")
              : "--"}
          </Typography>
        </div>
        {/* {!manageProject && (
          <>
            <Divider style={{ color: "#F2F3F4" }} />
            <Typography className={classes.company}>
              <img
                src={villa?.user_data?.profile_url}
                width="28px"
                height="28px"
                alt="profile_logo"
                style={{ borderRadius: "100%" }}
              />
              {villa?.user_data?.username}
              {villa?.user_data?.is_email_verified && (
                <img
                  src={Images.verified}
                  alt="verified"
                  style={{ marginLeft: 8 }}
                />
              )}
            </Typography>
          </>
        )} */}

        {villa?.cost && (
          <>
            <Divider style={{ color: "#F2F3F4", marginBottom: "1vh" }} />
            <div className={classes.rowJustified}>
              <Typography className={classes.row}>Cost:</Typography>

              <Typography className={classes.budget}>
                {formattedAmount(villa?.cost)}
              </Typography>
            </div>
          </>
        )}

        <>
          <div className={classes.rowJustified}>
            <Typography className={classes.row}>Updated At</Typography>
            {!md && <img src={Images.arrow} alt="arrow"></img>}
            <Typography className={classes.budget}>
              {" "}
              {villa?.updatedAt
                ? moment(villa?.updatedAt).format("MMM DD, YYYY")
                : "--"}
            </Typography>
          </div>
        </>
      </CardContent>
    </Card>
  );
};

export default PackageCard;
