import React, { useState, useEffect, useCallback } from "react";
import "./index.css";
import { getApiData } from "../../utils/APIHelper";
import BlueAbout from "../../components/BlueAbout/index";
import { formattedAmountWithoutAed } from "../../utils/CommonFunction";
import { useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Pagination,
  TableSortLabel,
  Typography,
  CircularProgress,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { Assignment } from "@mui/icons-material";
import { debounce } from "lodash";
import CInput from "../../components/CInput";
import ClearIcon from "@mui/icons-material/Clear";
import { format } from "date-fns";
import { Box, Grid, Paper } from "@mui/material";

function formatDisplayDate(date) {
  return date ? format(new Date(date), "MMM dd, yyyy") : "";
}

const Status = ({ status }) => {
  const statusStyles = {
    delivered: {
      backgroundColor: "rgb(204, 238, 233)",
      color: "rgb(3, 77, 3)",
    },
    paid: {
      backgroundColor: "rgb(235, 250, 221)",
      color: "#389e0d",
    },
    "ready for payout": {
      backgroundColor: "rgb(204, 238, 233)",
      color: "rgb(3, 77, 3)",
    },
    completed: {
      backgroundColor: "rgb(235, 250, 221)",
      color: "#389e0d",
    },
    ongoing: {
      backgroundColor: "#e6f7ff",
      color: "#096dd9",
    },
    processed: {
      backgroundColor: "#e6f7ff",
      color: "#096dd9",
    },
    "Not Started": {
      backgroundColor: "rgba(234, 229, 229, 0.82)",
      color: "#444",
    },
    default: {
      backgroundColor: "#FFF3DF",
      color: "#000",
    },
  };

  const { backgroundColor, color } =
    statusStyles[status.toLowerCase()] || statusStyles.default;

  return (
    <p
      style={{
        display: "inline-block",
        padding: "5px 10px",
        borderRadius: "4px",
        fontFamily: "Poppins-Medium",
        textTransform: "capitalize",
        fontSize: "14px",
        backgroundColor,
        color,
      }}
    >
      {status.charAt(0).toLowerCase() + status.slice(1)}
    </p>
  );
};

export default function Payments() {
  const { userData } = useSelector((state) => state.auth);

  const today = moment().format("YYYY-MM-DD");
  const upcomingEndDate = moment().add(7, "days").format("YYYY-MM-DD");
  const currentMonthStartDate = moment().startOf('month').format('YYYY-MM-DD');
  const currentMonthEndDate = moment().endOf('month').format('YYYY-MM-DD');

  const [projectDetails, setProjectDetails] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("dueDate");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [startDate, setStartDate] = useState(currentMonthStartDate);
  const [endDate, setEndDate] = useState(currentMonthEndDate);
  const [selectedTab, setSelectedTab] = useState(0); // 0: Overdue, 1: Upcoming, 2: All
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [startDateType, setStartDateType] = useState("text");
  const [endDateType, setEndDateType] = useState("text");
  const [summary, setSummary] = useState({});

  const handleDateChange = (dateSetter, dateTypeSetter) => (event) => {
    const rawDate = event.target.value;
    dateSetter(rawDate ? new Date(rawDate).toISOString().split("T")[0] : ""); // Convert to ISO format for backend if needed
    dateTypeSetter("text"); // Revert the input type immediately after selection
  };

  const handleFocus = (dateTypeSetter) => {
    dateTypeSetter("date"); // Set type to "date" on focus
  };

  const handleBlur = (dateTypeSetter) => {
    dateTypeSetter("text"); // Reset to "text" after user interaction
  };

  // Debounced search handler
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length >= 4 || value.length === 0) {
      debounceSearch(value);
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (userData?.contractor_data?.id) {
      fetchData(page, sortBy, sortOrder, startDate, endDate, debouncedSearch);
    }
  }, [
    userData?.contractor_data?.id,
    page,
    sortBy,
    sortOrder,
    startDate,
    endDate,
    selectedTab,
    debouncedSearch,
  ]);


  const fetchData = async (
    currentPage,
    currentSortBy,
    currentSortOrder,
    start,
    end,
    searchQuery
  ) => {
    setLoading(true);
    try {
      let filters = `contractorId:=:${userData.contractor_data.id}`;
      if (selectedTab !== 2) {
        filters += ":AND";
      }
      if (selectedTab === 0) {
        filters += `&filters=status:notLike:PAID:AND&filters=dueDate:<=:${today}`;
      } else if (selectedTab === 1) {
        filters += `&filters=status:notLike:PAID:AND&filters=dueDate:>:${today}:AND&filters=dueDate:<:${upcomingEndDate}`;
      } else if (selectedTab === 2) {
        if (start) filters += `:AND&filters=dueDate:%3E=:${start}`;
        if (end) filters += `:AND&filters=dueDate:%3C=:${end}`;
        if (searchQuery)
          filters += `:AND&filters=projectName:like:%${searchQuery}%`;
      }

      const response = await getApiData(
        `api/v2/dashboard/paymentOverview?object=payouts&page=${currentPage}&size=10&filters=${filters}&sortBy=${currentSortBy}&sortOrder=${currentSortOrder}`,
        "GET",
        {},
        {},
        true
      );
      setProjectDetails(response?.content || []);
      setTotalPages(response?.totalPages || 0);
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if (userData?.contractor_data?.id){
    fetchSummary(sortBy, sortOrder)
    }
  },[userData?.contractor_data?.id, selectedTab])


  const fetchSummary = async (
    currentSortBy,
    currentSortOrder,
  ) => {
    setLoading(true);
    try {
      let filters = `contractorId:=:${userData.contractor_data.id}`;
      if(selectedTab === 1){
        filters += `:AND&filters=status:notLike:PAID:AND&filters=dueDate:>:${today}:AND&filters=dueDate:<:${upcomingEndDate}`;
      } else if (selectedTab === 2){
        filters += `:AND&filters=dueDate:%3E=:${currentMonthStartDate}:AND&filters=dueDate:%3C=:${currentMonthEndDate}`
      }

      const response = await getApiData(
        `api/v2/dashboard/paymentOverview/summary?object=payouts&filters=${filters}&sortBy=${currentSortBy}&sortOrder=${currentSortOrder}`,
        "GET",
        {},
        {},
        true
      );
      setSummary(response || {});
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const handleSort = (column) => {
    const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
  };

  const stats = [
    { label: "Total Projects", value: summary.totalProjects },
    { label: "Total Paid Amount", value: summary.totalPaidAmount },
    { label: "Total Unpaid Amount", value: summary.totalUnPaidAmount },
  ];

  return (
    <Stack flex={1}>
      <div className="pageContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Overdue Payments" className="tableHeader" />
            <Tab label="Upcoming Payments" className="tableHeader" />
            <Tab label="All Payments" className="tableHeader" />
          </Tabs>
     
          {selectedTab === 2 && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              style={{ width: "45%" }}
            >
              <CInput
                placeholder="Project Name"
                value={search}
                onChange={handleSearchChange}
                inputProps={{ maxLength: 50 }}
                style={{ width: "50%" }}
                isWhiteBackground
              />
              <TextField
                value={formatDisplayDate(startDate)}
                onChange={handleDateChange(setStartDate, setStartDateType)}
                onFocus={() => handleFocus(setStartDateType)}
                onBlur={() => handleBlur(setStartDateType)}
                InputLabelProps={{ shrink: true }}
                placeholder="Start Date"
                fullWidth
                type={startDateType}
                InputProps={{
                  endAdornment: startDate && (
                    <IconButton onClick={() => setStartDate("")} className="iconButton">
                      <ClearIcon className="iconSvg"/>
                    </IconButton>
                  ),
                }}
              />
              <TextField
                value={formatDisplayDate(endDate)}
                onChange={handleDateChange(setEndDate, setEndDateType)}
                onFocus={() => handleFocus(setEndDateType)}
                onBlur={() => handleBlur(setEndDateType)}
                InputLabelProps={{ shrink: true }}
                placeholder="End Date"
                fullWidth
                type={endDateType}
                InputProps={{
                  endAdornment: endDate && (
                    <IconButton onClick={() => setEndDate("")} className="iconButton">
                      <ClearIcon className="iconSvg"/>
                    </IconButton>
                  ),
                }}
              />
            </Stack>
          )}
        </div>

      <div style={{display:"flex", flexDirection: "row", alignItems:"center", gap:"10px"}}>
        {stats?.map((stat, index) => (
          <div style={{width: "100%"}} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{  mb: 1, letterSpacing:"0", fontSize:
                "16px", color:"rgba(0,0,0,.45)"
               }}>
                {stat?.label}
              </Typography>
              <Typography style={{fontSize:"24px", color:"rgba(0,0,0,.85)"}}>
                {stat?.value?.toLocaleString()} 
              </Typography>
            </Paper>
          </div>
        ))}
      </div>

        <Stack className="tableContainer">
          {loading ? (
            <Stack justifyContent="center" alignItems="center" flex={1}>
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead style={{ backgroundColor: "rgb(243, 244, 249)" }}>
                    <TableRow>
                      <TableCell
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Project Name
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Payment Group
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Payment Group Status
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        <TableSortLabel
                          active={sortBy === "dueDate"}
                          direction={sortOrder.toLowerCase()}
                          onClick={() => handleSort("dueDate")}
                        >
                          Due Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        Amount (AED)
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Release Date
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Payout Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.length > 0 ? (
                      projectDetails.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Stack flex={1} direction="row" alignItems="center">
                              <Assignment
                                style={{
                                  color: "#4190e5",
                                  marginTop: "5px",
                                  marginRight: "8px",
                                }}
                              />
                              <Typography
                                fontSize="14px"
                                fontFamily="Poppins-Medium"
                                textTransform="capitalize"
                              >
                                {row?.projectName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell style={{ fontSize: "15px" }}>
                            {row.groupName}
                          </TableCell>
                          <TableCell style={{ fontSize: "15px" }}>
                            {row.paymentGroupStatus !== "Created" && (
                              <Status status={row.paymentGroupStatus} />
                            )}
                          </TableCell>
                          <TableCell style={{ fontSize: "15px" }}>
                            {moment(row?.dueDate).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "15px", textAlign: "right" }}
                          >
                            {formattedAmountWithoutAed(row.amount)}
                          </TableCell>
                          <TableCell style={{ fontSize: "15px" }}>
                            {row?.releaseDate &&
                              moment(row?.releaseDate).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell style={{ fontSize: "15px" }}>
                            {row.status !== "CREATED" && (
                              <Status status={row.status} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: 20, alignSelf: "center" }}
              />
            </>
          )}
        </Stack>
      </div>
      <BlueAbout />
    </Stack>
  );
}
