import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DescriptionIcon from "@mui/icons-material/Description";
import PackageIcon from "@mui/icons-material/CardGiftcard";
import PaymentIcon from "@mui/icons-material/Payment";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../redux/reducers/auth/actions";
import { useNavigate } from "react-router-dom";

const HamburgerMenu = ({classes}) => {
  const {  setActiveValue } = authActions;
  const { activeValue } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (tabIndex) => {
    navigate("/dashboard")
  
    dispatch(setActiveValue(tabIndex))
    handleClose();
  };

  const handleChange = () => {
   navigate("/payments")
    handleClose();
  };

  useEffect(()=>{
    if(activeValue === 1){
      dispatch(setActiveValue(0)) 
    }
  },[activeValue])

  return (
    <div>
      <IconButton aria-label="menu" onClick={handleClick} color="inherit" className={classes.menuIconButton}>
        <MenuIcon className={classes.menuIconSvg}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: "1vh 0vw",
            width: "150px",
          },
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick(0)}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Proposal" />
        </MenuItem>
        {/* <MenuItem onClick={() => handleMenuItemClick(1)}>
          <ListItemIcon>
            <PackageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Packages" />
        </MenuItem> */}
        <MenuItem onClick={() => handleChange()}>
          <ListItemIcon>
            <PaymentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Payments" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HamburgerMenu;
