import React, { useState } from "react";
import {
  Button,
  Grid,
  Modal,
  Typography,
  CircularProgress,
  Fade,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import CInput from "../../../../components/CInput";
import { getApiData, postFormData } from "../../../../utils/APIHelper";
import { Setting } from "../../../../utils/Setting";
import theme from "../../../../config/theme";
import { Add, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const AddVariantButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  gap: "2px",
  marginBottom: "12px",
  fontSize: "14px",
});

const Variants = ({ budget, getDetailsApiCall }) => {
  const [variants, setVariants] = useState([
    {
      name: "",
      description: "",
      included: true,
      image: null,
      isSaved: false,
      id: "",
    },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 500 : 900,
    height: sm ? 500 : 900,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    padding: "20px !important",
  };

  const handleAddVariant = () => {
    if (
      variants[variants.length - 1].name &&
      variants[variants.length - 1].image
    ) {
      setVariants([
        ...variants,
        {
          name: "",
          description: "",
          included: true,
          image: null,
          isSaved: false,
          id: "",
        },
      ]);
    } else {
      toast.error("Complete the existing variant first");
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setVariants([
      {
        name: "",
        description: "",
        included: true,
        image: null,
        isSaved: false,
        id: "",
      },
    ]);
  };

  const handleNameChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].name = value;
    setVariants(updatedVariants);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].description = value;
    setVariants(updatedVariants);
  };

  const handleImageChange = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file_1", file);
      formData.append("type", "boq_variant");
      formData.append("tags", "");

      try {
        const response = await postFormData(
          Setting.endpoints.packageFiles,
          formData
        );
        if (response.success) {
          toast.success("File uploaded successfully");
          const updatedVariants = [...variants];
          updatedVariants[index].image = response.images[0];
          setVariants(updatedVariants);
        } else {
          toast.error("File upload failed");
        }
      } catch (error) {
        toast.error("An error occurred during file upload");
      }
    }
  };

  const handleSaveVariant = async (index) => {
    const variant = variants[index];
    if (!variant.name || !variant.image) {
      toast.error("Please complete the variant before saving.");
      return;
    }

    setButtonLoader(true);
    const body = {
      name: variant.name,
      description: variant.description,
      included: true,
      imageMetadata: variant.image ? JSON.stringify(variant.image) : "",
    };

    try {
      const response = await getApiData(
        `api/v2/boq/${budget?.id}/variant`,
        "POST",
        body,
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        toast.success("Variant Added Successfully");
        getDetailsApiCall();
        const updatedVariants = [...variants];
        updatedVariants[index].isSaved = true;
        updatedVariants[index].id = response?.id;
        setVariants(updatedVariants);
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setButtonLoader(false);
    }
  };

  const handleDeleteImage = async (index) => {
    const variant = variants[index];
    setButtonLoader(true);
    try {
      const response = await getApiData(
        `api/project/delete-files/${variant.image.id}`,
        "POST",
        {},
        { "Content-Type": "application/json" },
        false
      );
      if (response.success) {
        toast.success(response.message);
        const updatedVariants = [...variants];
        updatedVariants[index].image = null;
        setVariants(updatedVariants);
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setButtonLoader(false);
    }
  };

  const handleDeleteVariant = async (variant, index) => {
    try {
      const response = await getApiData(
        `api/v2/boq/${budget?.id}/variant/${variant?.id}`,
        "DELETE",
        {},
        { "Content-Type": "application/json" },
        true
      );

      toast.success("Variant Deleted succesfully");
      setVariants(variants.filter((_, i) => i !== index));
      getDetailsApiCall();
    } catch (error) {
      toast.error(error.toString());
    }
  };

  return (
    <>
      <AddVariantButton
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Variants
      </AddVariantButton>

      <Modal open={openModal} onClose={() => handleClose()}>
        <Fade in={openModal} style={{ height: "70vh" }}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Add Variants</Typography>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </div>
            <div
              style={{
                marginTop: 12,
                height: 1,
                width: "100%",
                background: "#EEF0F3",
                marginBottom: 12,
              }}
            />
            <AddVariantButton
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddVariant}
            >
              Add Variant
            </AddVariantButton>
            <Grid
              container
              spacing={2}
              id="variants"
              sx={{
                overflowX: "hidden !important",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none", // IE and Edge
                scrollbarWidth: "none", // Firefox
                maxHeight: "80%",
              }}
            >
              {variants.map((variant, index) => (
                <Grid
                  key={index}
                  container
                  spacing={1}
                  style={{
                    backgroundColor: "#f7f7f7",
                    padding: 8,
                    borderRadius: 4,
                    marginRight: "10px",
                  }}
                >
                  <div style={{ marginRight: "1vw" }}>
                    {variant.image ? (
                      <div style={{ position: "relative" }}>
                        <img
                          src={variant.image.image}
                          alt="Uploaded BOQ"
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "2px solid #ddd",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        {!variant.isSaved && (
                          <IconButton
                            onClick={() => handleDeleteImage(index)}
                            style={{
                              position: "absolute",
                              top: "-6px",
                              right: "-6px",
                              padding: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              border: "1px solid red",
                            }}
                          >
                            <CloseIcon
                              style={{ fontSize: "12px", color: "red" }}
                            />{" "}
                          </IconButton>
                        )}
                      </div>
                    ) : (
                      <IconButton
                        component="label"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "8px",
                          border: "1px dashed #ccc",
                          margin: "0",
                          cursor: "pointer",
                          background: "#fff",
                        }}
                        disabled={!!variant.image}
                      >
                        <Add fontSize="small" />
                        <input
                          type="file"
                          hidden
                          onChange={(e) => handleImageChange(index, e)}
                          accept="image/*"
                        />
                      </IconButton>
                    )}
                  </div>
                  <Grid item xs={12} md={4}>
                    <CInput
                      label="Name"
                      placeholder="Enter Name"
                      value={variant.name}
                      onChange={(e) => handleNameChange(index, e.target.value)}
                      isWhiteBackground
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CInput
                      label="Description"
                      placeholder="Enter description"
                      value={variant.description}
                      onChange={(e) =>
                        handleDescriptionChange(index, e.target.value)
                      }
                      isWhiteBackground
                    />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    {variant.isSaved ? (
                      <IconButton
                        onClick={() => handleDeleteVariant(variant, index)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => handleSaveVariant(index)}
                        variant="contained"
                        style={{
                          marginTop: "22px",
                          display: "flex",
                          gap: "8px",
                        }}
                        size="small"
                      >
                        {buttonLoader ? <CircularProgress size={16} /> : "Save"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Variants;
