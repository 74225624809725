import React from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Rating,
  Typography,
  Stack,
} from "@mui/material";
import useStyles from "../styles";
import { useNavigate } from "react-router-dom";
import Images from "../../../config/images";
import { formattedAmount } from "../../../utils/CommonFunction";
import { useDispatch } from "react-redux";

const DashboardIcon = ({ children }) => {
  return (
    <Stack
      width="42px"
      height="42px"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      backgroundColor="rgba(39, 75, 241, 0.12)"
    >
      {children}
    </Stack>
  );
};

const ProposalSummary = ({
  userData,
  isLoading,
  states,
  resetProposalDetails,
  activeValue,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Grid item container gap={2}>
        <Grid item className={classes.card} flexDirection={"column"} lg={3}>
          <span
            className={"card_value"}
            style={{
              textAlign: "center",
            }}
          >
            Hi {userData?.contractor_data?.company_name}
          </span>
          <Typography className={classes.cardSubTxt}>
            Submit proposals to <br /> your customers
          </Typography>
          {activeValue === 0 ?
            <Button
              variant="contained"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                dispatch(resetProposalDetails());
                navigate("/create-proposal", {
                  state: {
                    isCreateProposal: true,
                    isUpdateDraftProposal: false,
                    isDraft: true,
                  },
                });
              }}
            >
              Create Proposal
            </Button> :
            <Button
              variant="contained"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                navigate("/create-package", {
                  state: {
                    isCreateProposal: true,
                  },
                });
              }}
            >
              Create Package
            </Button>}
        </Grid>
        <Grid
          item
          className={classes.card}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"center"}
        >
          <DashboardIcon>
            <img alt="Annual contracts value" src={Images.dollar} />
          </DashboardIcon>
          <Typography className={classes.annualC}>
            Annual contracts value
          </Typography>
          <span className={"card_value"}>
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              formattedAmount(states?.annaul_value) || "NA"
            )}
          </span>
        </Grid>
        <Grid
          item
          className={classes.card}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"center"}
        >
          <DashboardIcon>
            <img alt="Annual contracts value" src={Images.verify_green} />
          </DashboardIcon>
          <Typography className={classes.annualC}>Active Contracts</Typography>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <span className={"card_value"}>{states?.active_contracts}</span>
          )}
        </Grid>
        <Grid
          item
          className={classes.card}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"center"}
        >
          <DashboardIcon>
            <img alt="Annual contracts value" src={Images.eye} />
          </DashboardIcon>
          <Typography className={classes.annualC}>
            Profile Views today
          </Typography>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <span className={"card_value"}> {states?.total_views}</span>
          )}
        </Grid>
        <Grid
          item
          className={classes.card}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"center"}
        >
          <DashboardIcon>
            <Rating name="rating" value={4.5} max={1} readOnly />
          </DashboardIcon>
          <Typography className={classes.annualC}>455 Reviews</Typography>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <span className={"card_value"}> {states?.average_rating}</span>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default ProposalSummary;
